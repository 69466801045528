import {ExperimentsBag} from '@wix/wix-experiments'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isShowCouponInputInPaymentStepEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.ShowCouponInputInPaymentStep)

export const isSelectEventsManuallyEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.SelectEventsManually)

export const isFormDatePickerEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, 'specs.events.ui.FormDatePicker')

export const isFixMembersWaitlistEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, 'specs.events.ui.FixMembersWaitlist')
