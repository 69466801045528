import {getEventId, ModalResponse} from '@wix/wix-events-commons-statics'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {createAction} from '@reduxjs/toolkit'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {isTemplate} from '../../../../commons/selectors/instance'
import {openDialogModal, openModal} from '../../../../commons/services/modal'
import {getReservationId} from '../selectors/reservation'
import {GetState, StoreExtraArgs} from '../types'
import {NativeModal} from '../components/modals/constants'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {createAsyncAction} from '../services/redux-toolkit'
import {navigateBack, navigateToDetails, navigateToTicketsPicker} from './navigation'
import {cancelReservation, makeReservation} from './reservation'
import {changeEvent} from './event'
import {describeSeatingPlan} from './seating/plan'

export const openCheckoutUnavailable = createAsyncAction(
  'OPEN_CHECKOUT_UNAVAILABLE',
  (_, {dispatch, extra: {wixCodeApi}}) =>
    dispatch(
      openDialogModal({
        type: 'checkout-unavailable',
        onClose: () => wixCodeApi.location.to(wixCodeApi.location.url),
      }),
    ),
)
interface OpenUpgradeToPremiumArgs {
  eventId: string
  ticketQuantities: wix.events.ticketing.TicketReservationQuantity[]
}

export const openUpgradeToPremium = createAsyncAction<void, OpenUpgradeToPremiumArgs>(
  'OPEN_UPGRADE_TO_PREMIUM',
  ({eventId, ticketQuantities}, {dispatch}) =>
    dispatch(
      openDialogModal({
        type: 'upgrade-to-premium',
        onClose: (options: {message: {proceed?: any}}) => {
          if (options.message.proceed) {
            dispatch(makeReservation(eventId, ticketQuantities))
          }
        },
      }),
    ),
)

export const openTicketsDetailsModal = () => openNativeModal(NativeModal.ORDER_SUMMARY)

export const openTicketsDownloadModal = () => openNativeModal(NativeModal.TICKETS_DOWNLOAD)

export const openSeatingPlan = () => async (dispatch: Function) => {
  await dispatch(describeSeatingPlan())
  dispatch(openNativeModal(NativeModal.SEATING_PLAN))
}

export const openConfirmRsvp = () => openNativeModal(NativeModal.CONFIRM_RSVP)

export const openTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isTemplate(state) || isEditor(state)) {
    return null
  }

  return dispatch(
    openDialogModal({
      type: 'time-expired',
      onClose: () => dispatch(onCloseTimeExpiredModal()),
    }),
  )
}

const onCloseTimeExpiredModal = () => (dispatch: Function, getState: GetState, extra: StoreExtraArgs) => {
  const state = getState()
  const eventId = getEventId(state.event)
  const reservationId = getReservationId(state)

  dispatch(cancelReservation(eventId, reservationId))

  if (extra.wixCodeApi.location?.query?.reservationId) {
    return dispatch(navigateBack())
  }

  if (isMobile(state)) {
    return dispatch(navigateToTicketsPicker())
  }

  return dispatch(navigateToDetails())
}

export const openCantCompletePaymentModal = () =>
  openDialogModal({
    type: 'cant-complete-payment',
  })

export const openRecurringEventsModal = (eventId: string) => async (dispatch: Function) => {
  const response: ModalResponse = await dispatch(openModal({type: 'recurring-events', params: {eventId}}))
  if (response.message.slug) {
    dispatch(changeEvent(response.message.slug))
  }
  dispatch(setActiveElement(hookToAttributeSelector(DH.RECURRING_DATES_BUTTON)))
}

export const openNativeModal = createAction<NativeModal>('OPEN_NATIVE_MODAL')

export const closeNativeModal = createAction('CLOSE_NATIVE_MODAL')
